<template>
  <div class="my-2">
      <p :class="labelClass" class="text-uppercase">{{ label }}</p>
      <p
      :class="[textColor, contentWidth, contenClass]"
      >{{ text }}</p>
      <p v-html="html"></p>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },
        text: {
            type: String
        },
        html: {
            type: String,
            default: () => ''
        },
        textColor: {
            type: String,
            default: () => 'text-victoria'
        },
        labelClass: {
            type: String,
            default: () => 'text-victoria'
        },
        contenClass: {
            type: String,
            default: () => 'font-bold text-lg line-height-27'
        },
        contentWidth: {
            type: String,
            default: () => 'w-75p'
        }
    }
}
</script>

<style>

</style>